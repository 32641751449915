import Footer from "../Footer/Footer";
import Header from "../NavBar/Header";
import "./privacy.scss"
export const AMLPolicy = () => {
  return (
    <div>
      <Header />
      <div className="policy">
              <h1>Anti-Money Laundering Policy </h1>
              <h2>I. SCOPE AND APPLICABILITY</h2>
              <ul>
              <p><li> 1. The company is committed to ensuring that it maintains ethical business practices, complies with applicable law and its crowdfunding platform is not used for funding illegal activities. To achieve this objective, we have prepared this Anti-Money Laundering Policy (“AML Policy”) and request you to carefully read and follow the policy. This policy is in addition to your obligations under applicable laws on the prevention of money laundering and does not detract in any manner from those obligations. We urge you to be aware of your obligations under such applicable laws. The company’s ability to comply with its obligations under applicable law is dependent on your cooperation and we seek your cooperation via this policy.</li>
              <li>2. To ensure that we are not involved in any transactions that are known or suspected to be means of laundering money if any suspicious activity is noticed, users of this platform are urged to promptly contact government authorities to facilitate any action that may be needed.</li>
              <li>3. This money laundering policy applies to all persons using and accessing this web platform. This includes all donors, contributors, campaigners, and persons visiting our platform. All these persons are collectively referred to as “Users” or “users”.</li>
            </p>
            <h2>II. WHAT CONSTITUTES MONEY LAUNDERING AND SUSPICIOUS TRANSACTIONS</h2>
            <p><li>1. “Money laundering” is largely understood to mean any act or attempt (directly or indirectly and even by association in one step of a series of transactions) to conceal or disguise the origin and ownership of proceeds obtained illegally, such that it appears to have originated from legitimate sources; thus avoiding prosecution, conviction and confiscation of such proceeds. Terrorist organisations, tax evaders, smugglers, persons involved in bribery or any persons that receive money for illegal activities and/or through illegal means may launder money.</li>
            <li>2. Recognising transactions involving money laundering requires awareness of possible suspicious activity, which may arise at any time. Below is an indicative list of actions that persons associated with the company should be careful of. This list is not exhaustive, as every circumstance is not foreseeable. However, regardless of appearing together or individually, please be aware of the following situations that may be indications of money laundering activity:</li>
            <li>(i) Users that are reluctant to provide complete information, or those that provide incomplete, false or suspicious information and/ or are unwilling to comply with the company’s identification requirements;</li>
            <li>(ii) Users that appear as agents or representatives for other individuals or organisations, but refuse to provide complete information about such individuals or organisations;</li>
            <li>(iii) Any person, including a company employee, that is concerned about or seeks to avoid any reporting requirements or refuses to maintain required records;</li>
            <li>(iv) Payments made in cash or cash equivalents only (such as money orders, traveller’s cheques, internet currencies or prepaid cash cards);</li>
            <li>(v) Donations of large amounts that appear to be out of place or inconsistent with normal donation patterns, in the absence of any legitimate requirement for such donation;</li>
            <li>(vi) Requests for payments to be made in unrelated countries or to third parties;<br /></li>
            <li>(vii) Multiple partial payments from various parties on behalf of a single user and/or multiple partial payments from various locations;</li>
            <li>(viii) Users making payments in one form, then requesting refunds in other forms (for example, making payments by credit card, but requesting refunds in cash or by wire transfers);</li>
            <li>(ix) Users making contributions, followed by immediate requests to wire out or transfer the funds to a third party or firm, without connected purposes;</li>
            <li>(x) Users requesting for donations to be paid in cash or wired to a third party or firm, without connected purposes;</li>
            <li>(xi) On-boarding of users by our employees based on incomplete information or in contravention of our company’s policies;</li>
            <li>(xii) Acceptance of incentives by our employee for breaching our company policies;</li>
            <li>(xiii) Users connected to countries and/ or persons identified as non- cooperative by the Reserve Bank of India, Financial Action Task Force on Money Laundering established by the G-7 Summit in 1987, Office of Foreign Assets Control, US Department of Treasury and international organisations against money laundering.</li>
          </p>
          <h2>III. STEPS TO ENSURE COMPLIANCE</h2>
          <p>
          <li>1. Compliance with Applicable Law: Users must, at all times, ensure that they access and/ or utilise the web platform in compliance will all applicable law. Users contributing or donating to campaigns should also ensure that no funds used to contribute or donate to a campaign originate from any unlawful activity. Similarly, users collecting funds for a campaign must ensure that they are not used for any illegal purpose.</li>
          <li>2. Reporting Suspicious Activity: Users must, and without delay, report to relevant government authorities any suspicious activity or red flag that they might come across while accessing or using the web platform.</li>
          </p>
          <h2>IV. ACTION BY OUR COMPANY</h2>
          <p>
          <li>Upon receiving knowledge of suspicious transactions or any breach of applicable law or this policy, we may take one or more of the following steps:</li>
          <li>1. Probe into the incident;</li>
          <li>2. Report the incident directly to the appropriate government authority;</li>
          <li>3. Suspend or delete a campaign;</li>
          <li>4. Suspend or delete the user account(s);</li>
          <li>5. Other steps/actions that our management may deem appropriate.</li>
          </p>
          <h2>V. CONSEQUENCES OF AML POLICY VIOLATIONS</h2>
          <p><li>Users agree that irreparable damage would occur to the company if any of the provisions of this AML Policy are not complied with. Accordingly, users agree that the company is entitled to claim damages from users for violating this AML Policy in addition to any other remedy to which they are entitled at law or in equity.</li></p>
          <h2>VI. NO LIABILITY</h2>
          <p>
          <li>To the extent permitted under applicable law, the company shall have no liability in respect of any illegal transactions that take place on its platform. The company shall also not be liable for any consequences (monetary or otherwise) suffered by the stakeholders of a campaign or other users if it takes action (in its sole discretion) pursuant to this policy and applicable law.</li>
          </p>
          <h2>VII. COMPLIANCE AND CO-OPERATION</h2>
          <p>
          <li>This policy must be complied with by the user in addition to the local laws applicable to the users in their home jurisdictions. Users agree that upon being requested by this company or its legal counsel or any government authorities they shall fully cooperate with them in any formal or informal inquiry, investigation or other proceedings. In this regard, users agree that they will promptly provide all information or documents they may possess relevant to the inquiry, investigation or legal action or proceeding without any demur and at their own costs.</li>
          </p>
          <h2>VIII. MAINTENANCE AND DISCLOSURE OF RECORDS</h2>
          <p>
          <li>To help us comply with applicable law we obtain and record information that identifies users on our platform. This means we must ask you for certain identifying information, including copies of government-issued ID and such other information or documents that we consider appropriate to verify your identity. We hereby reserve the right to maintain records confirming the identity of its users and the transactions undertaken by them for as long as may be required under applicable law. We also reserve the right to disclose this information to government authorities in case of any inquiry, investigation or other proceedings initiated by them.</li>
          </p>
          <h2>IX. GOOD FAITH</h2>
          <p>
          <li>No policy, law or code of conduct can foresee every situation, and we rely on the good faith of its users to promptly report any concerns or ‘red flags’ envisaged by this AML Policy to government authorities. We presume that all information (including documents relating to the identity of its users) is true and accurate and the users represent the same to us by virtue of using the services of this company. </li>
          </p>
          <h2>X. AMENDMENT</h2>
          <p>
          <li>The company reserves the right to modify and amend this AML Policy at any time. The company will not be liable to any users or third parties for any modification or amendment. Users are recommended to review the AML Policy from time to time, for the most up-to-date version. In the event of modification or amendment to the AML Policy, continued use of the web platform, after notice of material changes, will constitute acceptance of the modified or amended terms. If the modifications or amendments to the AML Policy are not acceptable by any registered users, the only remedy would be to cease usage of the web platform, by deleting the relevant account or writing to us at contact@nerchapetti.com</li>
          </p>
          <h2>XI. GOVERNING LAW</h2>
          <p>
          <li>Any dispute or claim relating in any way to this AML Policy is subject to the exclusive jurisdiction of the courts in Kerala. Laws prevailing in India, without regard to principles of conflict of laws, will govern this AML Policy and any dispute related to/arising from this AML Policy, between the company and the concerned user.</li>
          </p>
          <h2>XII. SURVIVAL</h2>
          <p>
            <li>This AML Policy will survive the termination of the company’s relationship with the concerned user.</li>
         </p>
          <h2>XIII. QUERIES</h2>
          <p>
          <li>Should have any queries or require any assistance in relation to this policy, please write to us at contact@nerchapetti.com</li>
          </p>
          </ul>
      </div>
      <Footer />
    </div>
  );

};